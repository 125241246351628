.cards {
    margin: 2rem !important;
}

.cards-divider {
    min-height: 2px;
    margin: 0px 10px;
}

svg.p-icon.p-button-icon.p-button-icon-left {
    display: none;
}

.p-card.p-component.flex.flex-column.justify-content-center.items-center {
    margin: 0 !important;
}

.p-column-filter-menu {
    margin-left: 10px !important;
}



@media (max-width: 890px) {
    .admin-dashboard .col-4 {
        width: 100% !important;
    }

    .admin-dashboard .col-8 {
        width: 100% !important;
    }
}

.p-dialog {
    border-radius: 15px !important;
}

.p-dialog .p-dialog-footer {
    display: flex;
    justify-content: center;
    gap: 30px;
    border-bottom-right-radius: 15px !important;
    border-bottom-left-radius: 15px !important;
}

.p-dialog .p-dialog-header {
    text-align: center;
    color: #fff !important;
    padding: 1.5rem !important;
    border-radius: 15px 15px 0px 0px !important;
    background: #052941 !important;
}

.p-dialog .p-dialog-content {
    padding: 2rem !important;
}

// .p-dialog .p-dialog-header .p-dialog-title {
// font-size: 36px;
// }

.p-toolbar {
    background: none !important;
    // border-bottom: 1px solid #dee2e6 !important;
    padding: 0 0 1rem 0rem !important;
    border: none !important;
}

.images {
    align-items: center !important;
    justify-content: center !important;
    display: flex !important;
}

.admin-section {
    margin-top: 130px !important;

    .admin-img-section {
        img {
            width: 400px;
        }
    }
}

.admin-statuscard-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

// Admin Dashboard

@media (max-width: 1300px) {
    .admin-section {
        .admin-img-section {
            img {
                display: block;
                margin-left: auto;
                margin-right: auto;
                max-width: 100%;
            }
        }
    }
}

@media (max-width: 1008px) {
    .admin-dashboard .col-4 {
        width: 100% !important;
    }

    .admin-dashboard .col-8 {
        width: 100% !important;
    }
}

@media (max-width: 500px) {
    .admin-dashboard {
        .cards {
            margin: 1rem !important;
        }

        .admin-section {
            margin-top: 130px !important;

            .admin-img-section {
                img {
                    width: 400px;
                }
            }
        }
    }
}



/*   Student Page*/

.student-page {
    .student-page-head {
        display: flex;
        justify-content: space-between;
    }
}

@media screen and (max-width: 1260px) {
    .student-page .student-input {
        margin-right: -3.5rem !important;
    }
}


@media screen and (max-width: 1214px) {
    .student-page .student-input {
        margin-right: -4.5rem !important;
    }

    .student-page {
        .student-page-head {
            display: grid !important;
            justify-content: center !important;

            .student-page-left {
                margin-bottom: 24px;
            }
        }
    }
}



@media screen and (max-width: 710px) {

    .student-page-left .student-input,
    .student-page-left .p-button {
        display: flex;
        justify-content: center;

    }

    .admin-student-container .p-card {
        margin: 2rem !important;
    }

    .student-page-right .p-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }
}


@media screen and (max-width:640px) {

    .admin-student-container {
        .p-card {
            margin: 1rem !important;
        }

        .student-page-card {
            .m-6 {
                margin: 0rem !important;
            }
        }

    }
}

@media screen and (max-width:608px) {

    .student-page-left .student-input,
    .student-page-left .p-button {

        font-size: 14px;
        padding: 8px;
    }

    .student-page-right .p-button {

        font-size: 14px;
        padding: 8px;
    }

}



@media (max-width: 555px) {
    .admin-student-container {

        .table-section .p-datatable .p-column-header-content {
            justify-content: center !important;
            padding: 2rem;
        }

        .table-section .p-datatable .p-datatable-tbody>tr>td {
            padding: 1.5rem !important;
        }

        .student-page-head {
            .mx-6 {
                margin-left: 1rem !important;
                margin-right: 1rem !important;
            }

            .student-page-right {
                display: flex !important;
                flex-wrap: wrap !important;

            }

            .student-page-left {
                .student-input {
                    width: 50%;
                }

                .p-button-label .p-clickable {
                    padding: 0px;
                }

            }
        }
    }
}


/*   Principal page   */

.principal-page {
    .principal-page-head {
        display: flex;
        justify-content: space-between;
    }
}

@media screen and (max-width: 1260px) {
    .principal-page .principal-input {
        margin-right: -3.5rem !important;
    }
}


@media screen and (max-width: 1214px) {
    .principal-page .principal-input {
        margin-right: -4.5rem !important;
    }

    .principal-page {
        .principal-page-head {
            display: grid !important;
            justify-content: center !important;

            .principal-page-left {
                margin-bottom: 16px;
            }
        }
    }
}



@media screen and (max-width: 710px) {

    .principal-page-left .principal-input,
    .principal-page-left .p-button {
        display: flex;
        justify-content: center;

    }

    .admin-principal-container .p-card {
        margin: 2rem !important;
    }

    .principal-page-right .p-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }
}


@media screen and (max-width:640px) {

    .admin-principal-container {
        .p-card {
            margin: 1rem !important;
        }

        .principal-page-card {
            .m-6 {
                margin: 1rem !important;
            }
        }

    }
}

@media screen and (max-width:608px) {

    .principal-page-left .principal-input,
    .principal-page-left .p-button {

        font-size: 14px;
        padding: 8px;
    }

    .principal-page-right .p-button {

        font-size: 14px;
        padding: 8px;
    }

}



@media (max-width: 555px) {
    .admin-principal-container {
        .table-section .p-datatable .p-column-header-content {
            justify-content: center !important;
            padding: 2rem;

        }

        .principal-page-head {
            .mx-6 {
                margin-left: 1rem !important;
                margin-right: 1rem !important;
            }

            .principal-page-left {
                .principal-input {
                    width: 50%;
                }

                .p-button-label .p-clickable {
                    padding: 0px;
                }

            }
        }
    }
}




/*   Teacher page   */

.teacher-page {
    .teacher-page-head {
        display: flex;
        justify-content: space-between;
    }
}

@media screen and (max-width: 1260px) {
    .teacher-page .teacher-input {
        margin-right: -3.5rem !important;
    }
}


@media screen and (max-width: 1214px) {
    .teacher-page .teacher-input {
        margin-right: -4.5rem !important;
    }

    .teacher-page {
        .teacher-page-head {
            display: grid !important;
            justify-content: center !important;

            .teacher-page-left {
                margin-bottom: 16px;
            }
        }
    }
}



@media screen and (max-width: 710px) {

    .teacher-page-left .teacher-input,
    .teacher-page-left .p-button {
        display: flex;
        justify-content: center;

    }

    .admin-teacher-container .p-card {
        margin: 2rem !important;
    }

    .teacher-page-right .p-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }
}


@media screen and (max-width:640px) {

    .admin-teacher-container {
        .p-card {
            margin: 1rem !important;
        }

        .teacher-page-card {
            .m-6 {
                margin: 1rem !important;
            }
        }

    }
}

@media screen and (max-width:608px) {

    .teacher-page-left .teacher-input,
    .teacher-page-left .p-button {

        font-size: 14px;
        padding: 8px;
    }

    .teacher-page-right .p-button {

        font-size: 14px;
        padding: 8px;
    }

}



@media (max-width: 555px) {
    .admin-teacher-container {
        .table-section .p-datatable .p-column-header-content {
            justify-content: center !important;
            padding: 2rem;

        }

        .teacher-page-head {
            .mx-6 {
                margin-left: 1rem !important;
                margin-right: 1rem !important;
            }

            .teacher-page-left {
                .teacher-input {
                    width: 50%;
                }

                .p-button-label .p-clickable {
                    padding: 0px;
                }

            }
        }
    }
}

.action-button {
    color: black !important;
    background: none !important;
    border: none !important;
    padding: 10px !important;
}

.hide-header thead th {
    display: none;
    width: 100%;
}

.p-datatable .p-datatable-tbody>tr>td {
    text-align: left !important;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1.5rem;
    font-size: 18px;
    text-overflow: ellipsis !important;
    width: 150px !important;
    max-width: 100% !important;
    min-width: 150px !important;
}


.hide-header {
    width: 100%;
    display: block;
}

.show-header {
    display: block;
}

.hide-header thead th:first-child {
    display: table-cell;
}

.hide-header thead th:nth-child(2) {
    display: block;
}

.hide-header thead th:nth-child(3) {
    display: table-cell;
}

.hide-header thead th:nth-child(4) {
    display: table-cell;
    width: 300px !important;
}