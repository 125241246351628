.t3-template {
    border: 3px solid #000000;
    padding: 2rem;
    border-radius: 20px;
    min-height: 1000px;
    max-height: 1000px;
    overflow: scroll;

}

// .t3-template-content {
// }

.t3-t-content {
    padding: 30px;
}

.t3-left {
    border-right: 1px solid #FC5145;
    padding: 20px;
}

.t3-right {
    padding: 10px;
}

.t3-name {
    background-color: #052941;
    text-align: center;
    color: #FFFF;
    padding: 24px;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 90% */
    margin-bottom: 8px;
}

.t3l-label {
    padding: 10px 0px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 128.571% */
}

.t3l-details{
    padding: 10px 0px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
}

.t3personal-details {
    background-color: #052941;
    color: #FFFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px;
}

.t3-p-details{
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
}

.t3r-sections {
    padding: 10px 5px;
}

.t3r-label {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 128.571% */
    border-bottom: 1px solid #052941;
    padding-bottom: 12px;
    text-align: center;
}

.t3r-content {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    padding-top: 8px;
}
.t3r-contentlabel {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */
}

.t3-education {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    padding-top: 8px;
}

.t3r-subcontent {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width:767px) {
    .t3-template {
        padding: 1rem;
        .t3-p-details {
            font-size: 15px;
        }
    
        .t3-t-content {
            padding: 15px;
    
            .t3r-label {
                font-size: 15px;
                padding-bottom: 5px;
            }
    
            .t3r-content {
                font-size: 14px;
            }
        }
    
        .t3l-details {
            font-size: 15px;
        }
    
        .t3r-contentlabel {
            font-size: 15px;
        }
    
        .t2l-details {
            font-size: 14px;
        }
    }
}

@media screen and (max-width:600px) {
    .t3-template {
        padding: 1rem;

        .p-button {
            font-size: 14px !important;
        }
    

    .t3-p-details {
        font-size: 12px;
    }

    .t3personal-details {
        display: grid;
        align-items: center;
        justify-content: center;
        padding: 15px;
    }

    .t3-t-content {
        padding: 15px;

        .t3r-label {
            font-size: 12px;
            padding-bottom: 5px;
        }

        .t3r-content {
            font-size: 11px;
        }
    }

    .t3l-details {
        font-size: 13px;
    }

    .t3r-contentlabel {
        font-size: 12px;
    }

    .t2l-details {
        font-size: 12px;
    }
}
}