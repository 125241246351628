.cardWrapper {
  display: flex;
  justify-content: center;
  transition: transform 0.3s ease;
}

.cardWrapper.maximizedCard {
  transform: scale(1.2);
  z-index: 10;
}

.cardResponsive {
  max-width: 20rem;
}

.cardText {
  margin: 0;
  font-size: 16px;
  text-align: inherit;
}

.cardHeaderImage {
  width: 100%;
  height: 100%;
  padding: 10px;
}

.pi.pi-external-link {
  color: #333;
}

.healthcareButton {
  margin: 1rem;
  background-color: #fff !important;
  color: #0000EE !important;
  border: none !important;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
}

.dialogStyle {
  width: 70vw;
}

.dialogContent {
  padding: 0 20px;
}

.banner {
  position: relative;
  width: 100%;
  height: 30%;
  border-radius: 15px !important;
}

.bannerImage {
  width: 100%;
  height:150px;
  border-radius: 15px !important;
}

.bannerText {
    color: white;
    font-size: 24px;
    font-weight: bold;
    position: absolute;
    top: 35%;
    left: 16px;
}

.dialogInnerContent {
  padding: 0 10px;
}

.descriptionSection {
  margin-bottom: 20px;
}

.descriptionText {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: inherit;
}

.salarySection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 3px solid #052941;
  border-radius: 15px;
  margin-bottom: 10px;
}

.salaryDetails {
  display: flex;
  align-items: center;
  padding: 10px;
}

.salaryRange {
  font-family: "Poppins", sans-serif;
  color: #91bf82;
  font-size: 24px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.salarySubText {
  color: #988f8f;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.salaryDescription {
  font-size: 16px;
  color: #000000;
  text-align: inherit;
  padding: 10px 20px;
  flex: 1;
}

.educationSection {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  // overflow: overlay !important;
  img {
    width: 350px;
    height:180px;
  }

}

// .educationImage {
//   overflow: hidden !important;
// }

.educationTitle {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
}

.educationDescription {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.coursesSection {
  margin-top: 30px;
}

.coursesGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

/* Media Queries */
@media (min-width: 1025px) {
  .coursesGrid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .coursesGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Mobile devices */
@media (max-width: 767px) {
  .coursesGrid {
    grid-template-columns: 1fr;
  }
}

// .careersSection {
//   margin-top: 30px;
// }

.careersLinks {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  font: size 1px;
  color: #2196f3;
  justify-content: space-between;
  text-decoration: none;
}

.careersSection {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f9f9f9;
}

.careerTabs {
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tabContent {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 15px;
}
.tabLinkHeader {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 2px solid #000000;
}

.tabImage {
  max-width: 300px;
  height: 250px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  
}

.textContent {
  flex: 1;
}

.tabTitle, .tabTitle2 {
  // font-size: 24px;
  // font-weight: 500;
  // color: #333;
  // margin-bottom: 10px;
  color: #052941;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.tabDescription, .tabDescription2 {
  font-size: 16px;
  color: #555;
}

.Schoolingsection {
  margin-top: 20px;
}

.CostDetails {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  // align-items: center;
  height: 100px;
  padding: 10px;
  box-sizing: border-box;
  border: 3px solid #052941;
  border-radius: 15px;
  margin: 0 auto;
}

.CostofSchooling, .salary {
  // font-size: 24px;
  // color: #333;
  // font-weight: 500;
   
    font-family: "Poppins", sans-serif;
    color:#000000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.CostSubText, .salarytext {
  font-family: "Poppins", sans-serif;
    color: #91bf82;
    font-size: 16px;
    text-align:right;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

}
.salaryDetail {
  font-size: 12px;
  color: #000000;
  text-align: justify;
  padding: 10px;
  flex: 1;
}
.column {
  flex: 1; /* This ensures that both columns take up equal space */
}


.careerTabs .p-tabview-nav li {
  white-space: nowrap; /* to prevent text from wrapping and causing overflow */
  overflow: hidden;
  text-overflow: ellipsis;
  gap:2px;
  

  
}
.p-tabview-title {
  line-height: 1;
  white-space: nowrap;
  font-size: 16px;
}

.p-tabview-nav {
  display: flex;
  /* margin: 0; */
  padding: 0;
  list-style-type: none;
  border: none !important; 
  flex-wrap: wrap !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: white !important;
}

.coursesSection {
  .p-card .p-card-title {
    font-size: 1rem !important;
  }
  
 
}

.contentsection{
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.p-card-header img {
  width: 100%;
  border-radius: 15px;
}
// .activeHeader{
//   color: #3B8A6E;
//   border-bottom: 2px ;
// }
