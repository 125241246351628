.GR-view {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .circular-progress-bar {
        width: 150px;
        height: 150px;
    }

    .circular-bar {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.GR-Tabview {
    display: grid;
    gap: 1rem;

    .gr-section {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
    }

    .creditValue-section {
        display: grid;

        p {
            margin: 0;
            font-size: 16px;
        }

    }

    .progressbars-section {
        .p-datatable .p-datatable-thead>tr>th {
            font-size: 16px !important;
        }

        .p-datatable .p-datatable-tbody>tr>td {
            font-size: 16px !important;
        }

    }
}

@media screen and (max-width: 490px) {
    .GR-Tabview {
        .gr-section {
            display: grid;
            justify-items: center;
            gap: 0;
        }

        .progressbars-section {
            margin-top: 20px;

            .p-datatable .p-datatable-thead>tr>th {
                padding: 7px !important;
            }
        }
    }
}