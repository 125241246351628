.school-list-section {
  h2 {
    border-bottom: 5px solid #91BF82;
    width: fit-content;
  }
}

.improvement-section {
  padding-top: 112px;
}

.improvement-section {
  margin: 0 3rem;
}

.improvement-tools {
  margin: 3rem !important;
}

.changeExperimentsAndInitiatives {
  margin: 0 3rem !important;
}

.priority-section {
  margin: 0 3rem !important;
}

.redesign-section {
  margin: 0 3rem !important;
  padding-bottom: 40px;
}

.content-sections {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15),
    0px 19px 19px 0px rgba(0, 0, 0, 0.13), 0px 42px 25px 0px rgba(0, 0, 0, 0.08),
    0px 75px 30px 0px rgba(0, 0, 0, 0.02), 0px 117px 33px 0px rgba(0, 0, 0, 0);
}

.hiring-section {
  padding: 20px;

  .content-section {
    display: flex;
    align-items: center;

    img {
      box-shadow: 25px 25px 0px #ebb448;
    }
  }

  .content-section:last-child {
    display: block;

    .content-text,
    h2 {
      font-size: 22px;
    }

    color: #000;
  }
}

.priority-sections {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  .content-section1 {
    background: #052941;
    color: #fff;
  }
}

.p-color {
  font-size: 30px;
  color: red;
}

.system-banner-section {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;

  .item {
    flex-grow: 1;
    height: 450px;
    position: relative;
    transition: flex 0.5s;

    .content {
      bottom: 0 !important;
      position: absolute;
      width: 100%;
      text-align: center;
      p {
        padding-bottom: 40px;
      }
    }

    .default {
      h2 {
        bottom: 100px !important;
        position: absolute;
        width: 100%;
        text-align: center;
      }
    }

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
    }
  }

  .item.hovered {
    flex-grow: 6;
  }

  .item[data-order="1"] {
    background-image: url("../../Assets/System/Assess.png");
    background-position: center;
    background-size: cover;
  }

  .item[data-order="2"] {
    background-image: url("../../Assets/System/Design.png");
    background-position: center;
    background-size: cover;
  }

  .item[data-order="3"] {
    background-image: url("../../Assets/System/Enable.png");
    background-position: center;
    background-size: cover;
  }
  .item[data-order="4"] {
    background-image: url("../../Assets/System/Perform.png");
    background-position: center;
    background-size: cover;
  }

  .item[data-order="1"] .content {
    background-color: rgba(234, 130, 84, 0.85);
    color: #000000;
  }

  .item[data-order="2"] .content {
    background: rgba(235, 180, 72, 0.5);
    color: #000000;
  }

  .item[data-order="3"] .content {
    background: rgba(59, 138, 110, 0.5);
    color: #000000;
  }
  .item[data-order="4"] .content {
    background: rgba(72, 156, 211, 0.5);
    color: #000000;
  }

  .item[data-order="1"] .default {
    background-color: rgba(234, 130, 84, 0.85);
    color: white;
    .default-label {
      transform: rotate(-90deg);
    }
  }

  .item[data-order="2"] .default {
    background: rgba(235, 180, 72, 0.5);
    color: white;
    .default-label {
      transform: rotate(-90deg);
    }
  }

  .item[data-order="3"] .default {
    background: rgba(59, 138, 110, 0.5);
    color: white;
    .default-label {
      transform: rotate(-90deg);
    }
  }
  .item[data-order="4"] .default {
    background: rgba(5, 41, 65, 0.5);
    color: white;
    .default-label {
      transform: rotate(-90deg);
    }
  }
}

.school-list-section {
  .school-main-section {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 1rem;
  }

  .school-content-section {
    padding: 2rem;
    .school-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      flex-wrap: wrap;

      .p-button {
        font-size: 16px !important;
      }
    }
    .content {
      padding-bottom: 10px;
    }

  }
  .pagination{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    cursor: pointer;
  }

  .pagination div {
    margin: 0 5px;
    cursor: pointer;
  }

  .pagination div.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .pagination div.active {
    font-weight: bold;
  }
}

@media screen and (max-width: 900px) {
  .school-list-section {
    .school-main-section {
      display: grid;
      .school-image-section {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .school-content-section {
        padding: 0.5rem !important;
          .school-details {
            flex-wrap: wrap !important;
          }
      }
    }
  }
}

@media screen and (max-width: 1131px) {
  .content-section1 img {
    width: 300px;
  }

  .image-section-left {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 415px;
    }
  }
}

@media screen and (max-width: 500px) {
     .priority-sections{
      .p-4 {
        padding: 1rem !important;
    }
     }
    .school-list-section {
      .m-6 {
        margin: 2rem !important;
    }
      h2 {
        font-size: 23px !important;
      }
      .school-main-section {
        .school-image-section{
          display: none !important;
        }
        .schoolUrl{
          width: 200px !important;
          word-wrap: break-word !important;
          white-space: initial;
      }
    }
    }

  .improvement-section {
    h2 {
      font-size: 24px;
    }
  }
}

.school-list {
  width: 100%;
  height: auto;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
}

.network-banner {
  padding-top: 100px;

  .network-bg img {
    object-fit: cover !important;
    height: 335px !important;
  }

  .showcase {
    width: 100%;
    position: relative;
    color: white;
  }

  .showcase .network-bg img {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
  }

  .showcase .overlay {
    width: 100%;
    height: 335px;
    background: rgba(5, 41, 65, 0.54);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .showcase h2 {
    font-size: 36px;
  }

  .showcase p {
    margin: 0;
    font-size: 1.2em;
  }

  .content-right {
    border-radius: 15px;
    background: rgba(5, 41, 65, 0.72);
    display: flex;
    padding: 20px;
    align-items: center;
    gap: 20px;
    padding-top: 100px;

    img {
      width: 40px;
      height: 40px !important;
    }
  }

  .count-school {
    display: flex;
    justify-content: flex-end;
    position: relative;
    right: 30px;
  }

  .listSchool-section {
    display: flex;
    border-radius: 15px;
    gap: 30px;
    border-radius: 15px;
    background: rgba(5, 41, 65, 0.72);
  }
}

@media screen and (max-width: 768px) {
  .network-banner {
    .showcase .overlay {
      width: 100%;
      height: 335px;
      background: rgba(5, 41, 65, 0.54);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .showcase h2 {
      font-size: 28px;
    }

    .showcase p {
      font-size: 16px;
    }

    .listSchool-section {
      border-radius: 15px;
      background: rgba(5, 41, 65, 0.72);
      display: flex;
      padding: 20px;
      align-items: center;
      gap: 20px;
      img {
        width: 40px !important;
        height: 40px !important;
      }
    }

    .count-school {
      display: flex;
      justify-content: center;
    }
  }

  .system-banner-section {
    display: none !important;
  }
}

@media screen and (max-width: 490px) {
  .improvement-section {
    margin: 0 2rem;
  }
  .improvement-tools {
    margin: 2rem !important;
  }
  .changeExperimentsAndInitiatives {
    margin: 0 1.5rem !important;
  }
  .priority-section {
    margin: 0 2rem !important;
  }
  
  .redesign-section {
    margin: 0 2rem !important;
  }
  
}