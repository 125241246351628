.main-banner-section {
    margin: 0;
    padding: 0;
    padding-top: 100px;
    width: 100%;
    display: flex;
    align-items: center;


.item {
    flex-grow: 1;
    // height: 600px;
    height: 400px;
    position: relative;
    transition: all 300ms ease-in-out;

    .content {
        bottom: 0 !important;
        position: absolute;
        width: 100%;
        text-align: center;

        p {
            padding: 50px;
        }
    }

    .default {
        h2 {
            bottom: 100px !important;
            position: absolute;
            width: 100%;
            text-align: center;
        }

    }

    &:hover {
        flex-grow: 2.5;
    }

    &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
    }

}

.item[data-order="1"] {
    background-image: url('../../../Assets/AboutUs/Transforming-leaders.jpg');
    background-position: center;
    background-size: cover;
}

.item[data-order="2"] {
    background-image: url('../../../Assets/AboutUs/Transforming-Students.jpg');
    background-position: center;
    background-size: cover;
}

.item[data-order="3"] {
    background-image: url('../../../Assets/AboutUs/Transforming-Community.jpg');
    background-position: center;
    background-size: cover;
}

.item[data-order="1"] .content,
.item[data-order="1"] .default {
    background-color: rgba(234, 130, 84, 0.85);
    color: white;
}

.item[data-order="2"] .content,
.item[data-order="2"] .default {
    background-color: rgba(59, 138, 110, 0.50);
    color: white;
}

.item[data-order="3"] .content,
.item[data-order="3"] .default {
    background-color: rgba(5, 41, 65, 0.50);
    color: white;
}
}

.mob-system-banner-section {
    position: relative;
    display: none;
    padding-top: 100px;

    .content {
        position: relative;
        display: inline-block;
        margin-top: -7px;
        width: 100%;

        .overlay {
            display: grid;
            justify-content: center;
            align-items: center;
            align-content: center;
            position: absolute;
            top: 0;
            left: 0;
            color: white;
            padding: 20px;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            opacity: 10;
            transition: opacity 0.3s ease;

            h2 {
                font-size: 20px;
                font-weight: 700;
                text-align: left;
            }

            p {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
                margin-top: -6px;
            }

        }

        .image {
            width: 100%;
            height: auto;

            &:last-child {
                width: 100%;
                height: 300px;
                object-fit: cover;
                background-position: center;
            }
        }
    }

}

@media screen and (max-width: 767px) {
    .main-banner-section {
        display: none !important;
    }

    .mob-system-banner-section {
        display: block !important;
    }
}