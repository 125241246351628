.profilecard-container .p-card .p-card-body {
    // box-shadow: 0px 6px 15px 0px #CCCCCC;
    border-radius: 10px;
}

.profilecard-container .p-card .p-card-content {
    padding: 1px !important;
}

.profilecard-container {
    .avatar {
        padding-left: 20px;
        text-align: center;
    }

    img {
        width: 7rem;
        height: 7rem;
    }
}

.profilecard-container .p-avatar.p-avatar-xl {
    width: 8rem !important;
    height: 8rem !important;
}

.profile-name {
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    height: 48px;
    top: 158px;
    left: 199px;
    margin-left: 20px;
    color: #000000;
    margin-bottom: 10px;
    margin-top: 14px;
}
.profile-student-name{
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    height: 48px;
    top: 158px;
    left: 199px;   
    margin-left: 20px;  
    color: #000000; 
    // margin-bottom: 10px;
    // margin-top: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.school-id{
    color: #2F2E41;
    display: flex;
    flex-direction: row;
    margin-left: 20px;
}

.id {
    margin: 10px;
}

.grade-section {
    display: flex;
    flex-direction: row;
    margin-left: 20px;
}

.profile-card-bottom {
    padding: 1.5rem 1rem 1rem 1rem;
    justify-content: space-evenly;
    text-align: start;
    align-items: flex-start;
}

.year-section {
    display: flex;
    flex-direction: row;
    margin-left: 20px;
}

.sub-text{
    padding-left: 15px;
    line-height: 50px;
    color: #2F2E41;
    font-size: 16px;
}

.not-student {
    // min-height: 230px;
    align-items: center;
    text-align: center;

    .profile-card-top {
        padding-top: 40px;
        justify-content: center;
    }
}

@media only screen and (max-width: 1550px) {
    .id {
        font-size: 14px;
    }

    .profilecard-container .p-avatar.p-avatar-xl {
        width: 7rem !important;
        height: 7rem !important;
    }

    .profilecard-container .avatar {
        padding-top: 20px;
        text-align: center;
    }
    .profilecard-container .p-card .p-card-content {
        padding: 0px !important;
    }
}

@media only screen and (max-width: 1400px) {

    .profilecard-container {
        .avatar {
            padding-left: 20px;
            text-align: center;
        }
    
        img {
            width: 5rem !important;
            height: 5rem !important;
        }
    }

    .id {
        font-size: 14px;
    }

    .profilecard-container .p-avatar.p-avatar-xl {
        width: 4rem !important;
        height: 4rem !important;
    }

    .profilecard-container .avatar {
        text-align: center;
    }

    .not-student .profile-card-top {
        padding-top: 10px !important;
    }
    .profile-name {
        font-size: 28px;
    }
    .profile-student-name{
        font-size: 20px;
    }
    .not-student {
        min-height: 214px !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
}


@media (max-width: 1110px) {

    .profilecard-container .p-card .p-card-content {
        // padding: 5px !important;
    }

    .student-dashboard {
        .profile-student-name {
            font-size: 20px;
        }
        .school-id{
            svg{
                width: 25px !important;
            }
        }
        .grade-section {
            svg{
                width: 25px !important;
            }
        }
        .year-section{
            svg{
                width: 25px !important;
            }
        }
        .profile-student-name {
             margin-left: 0px !important; 
        }
    }
}

@media (max-width: 500px) {

    .profilecard-container {
        .icon-card{
            width: 25px !important;
        }
        .icon-book{
            width: 25px !important;
        }
        .icon-user{
            width: 25px !important;
        }
    }
}