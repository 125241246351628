.student-profile {
    display: flex;
    justify-content: center;
    padding: 2rem;
    padding-top: 150px;

    .desktop-profile-view {
        display: block;
    }

    .mobile-profile-view {
        display: none;
    }

    .name-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .user-details {
        padding-left: 2rem;

        .name {
            margin-top: 0;
        }

        .id {
            font-size: 16px;
            margin: 0;
        }
    }

    .p-avatar img {
        width: 150%;
        height: 150%;
    }

    .edit-button {
        margin-left: 0.5rem;
    }

    .card {
        width: 100%;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        padding: 2rem;
        background: #fff;
        border: 2px solid #ddd;
        border-radius: 8px;

        .header {
            display: flex;
            align-items: center;
            margin-bottom: 2rem;
            border: 2px solid #ddd;
            border-radius: 8px;
            padding: 2rem;

            .info {
                margin-left: 1rem;
                flex-grow: 1;

                h1 {
                    margin: 0;
                    font-size: 2.5rem;
                    font-weight: 600;
                }

                p {
                    font-size: 1rem;
                    color: #666;
                }
            }

            .edit-button {
                margin-left: 0.5rem;
            }
        }

        .forms-section {
            border: 2px solid #ddd !important;
            padding: 2rem !important;
            border-radius: 8px !important;

            h2 {
                font-size: 1.5rem;
                font-weight: 600;
                margin-bottom: 1rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                // border-bottom: 2px solid #ccc;
                padding-bottom: 0.5rem;
                border-radius: 8px;
                margin-bottom: 2rem;

            }



            .form-row {
                display: flex;
                justify-content: space-between;
                margin-bottom: 1rem;

                .form-group {
                    flex: 1;
                    margin-right: 0.5rem;

                    label {
                        display: block;
                        font-weight: bold;
                        margin-bottom: 0.5rem;
                        color: #808080;

                    }

                    input,
                    select {
                        width: 100%;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            .address-header {
                font-size: 1.25rem;
                font-weight: bold;
                margin-top: 1rem;
                margin-bottom: 0.5rem;
                color: #808080;
            }

            .address {
                margin-bottom: 1rem;

                .form-group {
                    margin-bottom: 0.5rem;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .save-button {
                width: 100%;
            }
        }

        .full-width {
            width: 100%;
        }

        .grade-dropdown {
            width: 100% !important;
        }

        .documents-section {
            margin-top: 2rem;
            border: 2px solid #ddd;
            padding: 2rem;
            border-radius: 8px;

            h2 {
                font-size: 1.5rem;
                font-weight: 600;
                margin-bottom: 0.5rem;
            }

            .upload-section {
                display: inline-flex;
                align-items: center;
                gap: 1rem;

                .uploaded-resume-name {
                    font-style: italic;
                }

                .generate-button {
                    margin-left: auto;
                }
            }
        }

        .edit-button-container {
            display: flex;
            align-items: center;
        }

        .edit-button {
            float: right;
            border: none;
            background-color: transparent;
            color: #5b5b5b;
            margin: 0.5rem;
            box-shadow: none;
            font-size: 1rem;
            display: flex;
            align-items: center;
            cursor: pointer;

            .pi {
                margin-right: 0.5rem;
            }

            &:hover {
                background-color: #f0f0f0;
            }
        }
    }

    @media screen and (max-width: 1200px) {
        .name-container {
            flex-wrap: wrap;
            gap: 1rem;
        }

        .id {
            text-align: left !important;
        }

        .user-details {
            padding-left: 0;
            text-align: center;
        }

        .edit-button {
            margin-left: 0;
            margin-top: 0.5rem;
        }

        .header {
            flex-wrap: wrap;
            gap: 1rem;

            .info {
                margin-left: 0;
                margin-top: 1rem;
            }
        }
    }

    @media screen and (max-width: 992px) {
        padding-top: 120px;

        .id {
            text-align: left !important;
        }
    }

    @media screen and (max-width: 768px) {
        // padding-top: 80px;

        .p-avatar img {
            width: 120%;
            height: 120%;
        }

        .id {
            text-align: left !important;
        }

        .edit-button {
            margin-left: 0;
            margin-top: 0.5rem;
            float: none;
            padding: 0.5rem;
        }

        .card {
            padding: 1.5rem;
        }

        .header {
            padding: 1.5rem;

            .info {
                margin-top: 1rem;
            }
        }

        .forms-section {
            padding: 1.5rem !important;

            .form-group {
                margin-right: 0;
            }
        }

        .documents-section {
            padding: 1.5rem;
        }
    }

    @media screen and (max-width: 576px) {
        .p-avatar img {
            width: 100%;
            height: 100%;
        }

        .id {
            text-align: left !important;
        }

        .edit-button {
            margin-top: 1rem;
        }

        .card {
            padding: 1rem;
        }

        .header {
            padding: 1rem;

            .info {
                margin-top: 0.5rem;
            }
        }

        .forms-section {
            padding: 1rem !important;
        }

        .documents-section {
            padding: 1rem;
        }
    }
}

@media screen and (max-width: 768px) {
    .id {
        text-align: left !important;
    }

    .forms-section {
        .form-row {
            flex-direction: column;

            .form-group {
                margin-right: 0;
                margin-bottom: 1rem;
            }
        }
    }


    @media screen and (min-width: 769px) and (max-width: 1024px) {
        .id {
            text-align: left !important;
        }

        .forms-section {
            .form-row {
                justify-content: space-between;

                .form-group {
                    flex: 0 0 calc(30% - 1rem);
                    margin-right: 1rem;
                    margin-bottom: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .student-profile {
        padding: 100px 20px 0;

        .desktop-profile-view {
            display: none;
        }

        .mobile-profile-view {
            display: block;
            place-items: flex-start;
            width: 100%;

            .mob-view-PI {
                .edit-button {
                    padding: 0;
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 0;

                    a {
                        color: #1575E5;
                        text-decoration: none;
                        font-size: 18px;
                        cursor: pointer;
                    }
                }

                .header {
                    display: flex;
                    padding: 0;

                    .user-details h1 {
                        font-size: 22px;
                        text-align: left;
                    }
                }

                .forms-section {
                    margin-top: 20px;
                    padding: 0 !important;

                    .address-header {
                        font-size: 22px;
                    }

                    .form-row {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 1rem;

                        .form-group {
                            flex: 1;

                            label {
                                display: block;
                                font-weight: bold;
                                margin-bottom: 0.5rem;
                                color: #808080;
                                font-size: 18px !important;
                            }

                            input,
                            select {
                                width: 100%;
                            }

                            &:last-child {
                                margin-right: 0;
                            }

                            .grade-dropdown {
                                width: 100% !important;
                            }
                        }
                    }

                    .save-button {
                        width: 100%;
                    }
                }
            }
        }

        .mobile-view-doc {
            .documents-section {
                padding: 0 !important;

                p {
                    margin: 0 !important;
                    font-size: 18px;
                }

                .upload-section {
                    display: grid;
                    gap: 20px;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    padding: 10px 0;

                    .show-resume {
                        align-items: center;
                    }

                    .principal-page-left {
                        flex-wrap: wrap;
                        justify-content: flex-end;

                        .file-upload-option {
                            flex-wrap: wrap;
                        }
                    }
                }
            }
        }
    }
}