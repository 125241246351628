.main-s {
    width: 100%;
    height: 100vh;

    .spinner-section {
        .spinner-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 9999;
        }
        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
    
            100% {
                transform: rotate(360deg);
            }
        }
        .p-progress-spinner-circle {
            stroke: #052941 !important;
            stroke-width: 5 !important;
            animation: p-progress-spinner-dash 1.5s ease-in-out infinite, 6s ease-in-out infinite !important;
        }
    }

    .container {
        position: fixed;
        z-index: 99;
        background: white;
        max-width: unset;
    }

    .backBtn {
        width: 100%;
        height: 50px;
        color: blueviolet;
        padding: 0 40px;
        position: relative;
        top: 125px;
        display: flex;
        align-items: center;

        Link {
            cursor: pointer;
        }
    }

    .card {
        width: 100%;
        height: auto;
        display: flex;
        // flex-direction: column;
        // justify-content: center;
        gap: 25px;
        padding: 40px;

        position: relative;
        top: 110px;

        .left {
            width: 55%;
        }


        .right {
            width: 100%;

            .grid {
                align-items: center;

                .circular-progress-bar {
                    margin: unset;
                }
            }
        }

    }

}

.dd {
    position: relative;
    top: 90px;
    padding: 30px;
    background-color: #ffff;
    p{
        margin: 0;
    }

    hr {
        border-top: 4px solid #91BF82 !important;
        margin: unset;
    }

    .p-accordion .p-accordion-tab {
        margin-bottom: unset !important;
    }

    .p-accordion-header-link {
        height: auto !important;
    }

    .radio-buttons {
        margin-bottom: unset !important;
    }

    .p-accordion-content {

        .flex_Sec {
            display: flex;
            flex-wrap: wrap;
            gap: 50px;

            .border {
                border-left: 5px solid #D9D9D9;
                display: flex;
                flex-direction: column;
                gap: 20px;

                div {
                    position: relative;
                    left: 15px;
                }

                span {
                    position: relative;
                    left: 15px;
                }
            }
        }
    }

    .p-accordion-header-text {
        font-size: 24px;
        font-weight: bold;

        div {
            display: flex;
            align-items: center;
        }
    }

    .section1 {
        width: 340px;
        height: auto;
        border-left: 5px solid #D9D9D9;
        position: relative;
        left: 10px;

        p {
            position: relative;
            left: 20px;
        }
    }

    .section3 {
        width: 450px;
        height: auto;
        position: relative;
        left: 10px;

        p {
            position: relative;
            left: 20px;
        }
    }

    .section4 {
        width: 100%;
        height: 60px;
        margin: auto;
        border-bottom: 4px solid #ECF2FE !important;
        display: flex;
        align-items: center;

        div {
            width: 100%;
            font-size: 20px;
        }
    }

    .top {
        border-top: 4px solid #ECF2FE !important;
    }

    .qus {
        display: flex;
        align-items: center;
        gap: 20px;
        width: 95%;
        margin: auto;

        p {
            font-weight: bold;
            font-size: 18px;
        }

        span {
            font-size: 18px;
        }
    }

    .p-datatable {
        width: 95%;
        margin: auto;
        border-radius: 10px;
        background: #EA8254;
        border-bottom: unset;
        margin: 1rem;
        display: block !important;

        th {
            min-width: 200px;
        }
    }

    .personal-section {
        .personal-field {
            font-family: Poppins;
            font-size: 20px !important;
            font-weight: 400;
            line-height: 30px;
            color: #7C7878 !important;
            margin: 2px 5rem 2px;
        }

        .personal-skills-section {
            h3 {
                font-family: Poppins;
                font-size: 20px;
                font-weight: 600;
                line-height: 30px;
            }

            .skills-button {
                font-family: Poppins;
                font-size: 20px;
                font-weight: 400;
                line-height: 30px;
                padding: 15px;
                background-color: #052941;
                color: white;
            }
        }

        .personal-statement-section {
            display: grid;
            align-items: flex-start;
            width: 100%;
            h3 {

                font-family: Poppins;
                font-size: 20px;
                font-weight: 600;
                line-height: 30px;
            }
        }

        .personal-teacher-section {
            display: grid;
            align-items: flex-start;
            width: 100%;
            h3 {

                font-family: Poppins;
                font-size: 20px;
                font-weight: 600;
                line-height: 30px;
            }
        }
    }

    .career-sec {
        .career-field {
            font-family: Poppins;
            font-size: 20px !important;
            font-weight: 400;
            line-height: 30px;
            color: #7C7878 !important;
            margin: 2px 5rem 2px;
        }
    }

    .career-section {
        .results-section {
            .result-heading {
                font-family: Poppins;
                font-size: 20px;
                font-weight: 700;
                line-height: 30px;
                text-align: left;
            }

            span,
            p {
                font-family: Poppins;
                font-size: 20px;
                font-weight: 400;
                line-height: 30px;
                text-align: left;
                margin: 0;

            }
            .line{
                border-left: 2px solid #91BF82;
                padding-left: 1rem;
            }
        }

        .riasec-section {
            .riasec-heading {
                font-family: Poppins;
                font-size: 20px;
                font-weight: 700;
                line-height: 30px;
                text-align: left;
            }
            .line{
                border-left: 2px solid #91BF82;
                padding-left: 1rem;
            }
        }

        .selection-section {
            .selection-heading {
                font-family: Poppins;
                font-size: 20px;
                font-weight: 600;
                line-height: 30px;
                text-align: left;
            }

            .selection-head {
                font-family: Inter;
                font-size: 20px;
                line-height: 30px;
                text-align: left;

            }
            .line{
                border-left: 2px solid #91BF82;
                padding-left: 1rem;
            }
        }

        .reason-section {
            .reason-heading {
                font-family: Poppins;
                font-size: 20px;
                font-weight: 600;
                line-height: 30px;
                text-align: left;
            }
        }

        .teacher-section {
            .teacher-heading {
                font-family: Poppins;
                font-size: 20px;
                font-weight: 600;
                line-height: 30px;
                text-align: left;
            }
        }

       
    }
    .career-teacher-section {
        display: grid;
        align-items: flex-start;
        width: 100%;
        h3 {

            font-family: Poppins;
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
        }
    }
    .academic-section {
        .academic-field {
            font-family: Poppins;
            font-size: 20px !important;
            font-weight: 400;
            line-height: 30px;
            color: #7C7878 !important;
            margin: 2px 5rem 2px;
        }
    }

    .academic-sec {
        border-left: 2px solid #91BF82;
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 10px;

        span {
            font-family: Poppins;
            font-size: 20px;
            font-weight: 700;
            line-height: 30px;
            text-align: left;
        }

        p {
            font-family: Poppins;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            text-align: left;


        }
    }

    .academic-teacher-section {
        display: grid;
        align-items: flex-start;
        width: 100%;
        h3 {

            font-family: Poppins;
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
        }
    }

    .pathway-section {
        .pathway-field {
            font-family: Poppins;
            font-size: 20px !important;
            font-weight: 400;
            line-height: 30px;
            color: #7C7878 !important;
            margin: 2px 5rem 2px;
        }
    }

    .pathways-details {
        width: 100%;

        .line {
            border-left: 2px solid #91BF82;
            padding-left: 1rem;
        }
    }

    .pathway-teacher-section {
        display: grid !important;
        align-items: flex-start;
        width: 100%;
    }

    .progress-section {
        .progress-field {
            font-family: Poppins;
            font-size: 20px !important;
            font-weight: 400;
            line-height: 30px;
            color: #7C7878 !important;
            margin: 2px 5rem 2px;
        }
    }
   
    .scores-section {
        .scores-field {
            font-family: Poppins;
            font-size: 20px !important;
            font-weight: 400;
            line-height: 30px;
            color: #7C7878 !important;
            margin: 2px 5rem 2px;
        }
    }

    .scores-teacher-section {
        display: grid;
        align-items: flex-start;
        width: 100%;
        h3 {

            font-family: Poppins;
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
        }
    }
    .activities-section {
        .activities-field {
            font-family: Poppins;
            font-size: 20px !important;
            font-weight: 400;
            line-height: 30px;
            color: #7C7878 !important;
            margin: 2px 5rem 2px;
        }
    }
    .activities-teacher-section {
        display: grid;
        align-items: flex-start;
        width: 100%;
        h3 {

            font-family: Poppins;
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
        }
    }

    .p-datatable .p-datatable-thead>tr>th {
        color: #FFFFFF !important;
    }
}


.student-info-button {
    width: 100%;
    height: 100px;
    position: relative;
    top: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0px 40px;

    Button {
        // width: 259px;
        height: 60px;
        border-radius: 10px;
        display: flex;
        align-items: center;

        .p-button-label {
            font-size: 16px;
        }
    }

    .dialogSec {
        width: 96%;
        height: 83vh;
        background: #ffff;
        position: fixed;
        top: 150px;
        right: 10px;
        left: 10px;
        margin: auto;
        z-index: 999;
        overflow: scroll;
        box-shadow: 0px 6px 15px 0px #CCCCCC;

        .nav {
            width: 96%;
            height: 78px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 30px;
            // position: fixed;
            left: 0;
            right: 0;
            margin: auto;
            background-color: #ffff;
            z-index: 999;

            i {
                font-weight: bold;
            }
        }

        .inputFeild {
            width: 95%;
            height: auto;
            display: flex;
            justify-content: space-between;
            margin: auto;
            flex-wrap: wrap;
            gap: 20px;
            position: relative;
            // top: 100px;

            div {
                display: flex;
                gap: 20px;
                align-items: center;

                .p-inputtext {
                    border: none !important;
                    border-bottom: 2px solid #AEB3B7 !important;
                    border-radius: 0px !important;
                }
            }
        }

        .heading {
            width: 95%;
            margin: auto;
            font-size: 24px;
            position: relative;
        }

        .mainCondent {
            width: 95%;
            margin: auto;
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            position: relative;

            .condentSec {
                width: 350px;
                height: auto;
                border-left: 5px solid #D9D9D9;
                display: flex;
                flex-direction: column;
                gap: 15px;

                p {
                    margin: 0 10px;
                }
            }
        }


        .pos {
            position: relative;
            width: 95%;
            margin: auto;
        }

        .subHeading {
            width: 95%;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            font-weight: 700;
            border-bottom: 4px solid #ECF2FE !important;
        }

        .header {
            width: 95%;
            height: 70px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 24px;
            margin: auto;
            border-bottom: 4px solid #ECF2FE !important;
        }

        .qus1 {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;

            p {
                font-weight: 600;
            }
        }


        .table {
            display: flex;
            flex-direction: column;
            gap: 20px;
            z-index: 999;

            .datatablesSec {
                width: 100%;
                height: auto;
                margin: auto;
                position: relative;
                top: 100px;
                display: flex;
                flex-direction: column;
                gap: 20px;

                .header {
                    width: 95%;
                    height: 70px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-size: 24px;
                    margin: auto;
                    border-bottom: 4px solid #ECF2FE !important;
                }

                .p-datatable {
                    position: relative;
                    background: #EA82544D;
                    width: 95%;
                    margin: auto;
                    border-radius: 10px;
                }

            }
        }

        .gap {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        .condentSec1 {
            width: 95%;
            // height: 60px;
            position: relative;
            top: 100px;
            display: flex;
            margin: auto;
            justify-content: space-between;
            align-items: flex-start;

            height: unset;
            flex-wrap: wrap;
            gap: 20px;

            .sec-header {
                display: flex;
                align-items: center;
                height: 40px;
                gap: 15px;
                font-size: 16px;
                font-weight: 600;
                position: relative;
                left: 10px;
            }

            .sec {
                display: flex;
                flex-direction: column;
                align-items: center;

                .sec-header {
                    width: -webkit-fill-available;
                }

                .boder-add {
                    border-left: 1px solid #00000040;
                }
            }

        }

        .sec2 {
            position: relative;
            top: 100px;
            width: 95%;
            margin: auto;

            .boder-add {
                border-left: 1px solid #00000040;

                .sec-header {
                    display: flex;
                    align-items: center;
                    height: 40px;
                    gap: 15px;
                    font-size: 16px;
                    font-weight: 600;
                    position: relative;
                    left: 10px;
                }
            }

        }


        .dataTableSection {

            position: relative;
            top: 100px;
            width: 95%;
            margin: auto;

            .p-datatable-wrapper {
                border-radius: 10px;
            }

            .p-datatable-thead {
                background-color: #EA82544D;
            }
        }

        .position {
            width: 95%;
            margin: auto;
            margin-bottom: 15px;
            margin-top: 15px;
        }

        .b {
            border-bottom: unset !important;
        }


        .downloadBtn {
            width: 95%;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;

            .p-button-label {
                font-size: 16px;
            }

        }

    }

    .personal {
        padding-top: 7rem;
        .personal-skills-section {
            h3 {
                font-size: 22px;
            }
        }

        .personal-statement-section {
            h3 {
                font-size: 22px;
            }

            p {
                font-size: 20px;
            }
        }

        .note {
            h3 {
                font-size: 22px;
            }
        }
    }

    .career {
        .results-section {
            .result-heading {
                font-size: 22px;
            }

            span {
                font-size: 22px;

            }

            p {
                font-size: 20px;

            }

            .line {
                border-left: 2px solid #91BF82;
                padding-left: 1rem;
            }

        }

        .riasec-section {
            .riasec-heading {
                font-size: 22px;
            }

            span {
                font-size: 20px;

            }

            .line {
                border-left: 2px solid #91BF82;
                padding-left: 1rem;
            }
        }

        .selection-section {
            .selection-heading {
                font-size: 22px;
            }

            .selection-head {
                font-size: 20px;
            }

            span {
                font-size: 20px;
            }

            .line {
                border-left: 2px solid #91BF82;
                padding-left: 1rem;
            }
        }

        .reason-section {
            .reason-heading {
                font-size: 22px;
            }

            span {
                font-size: 20px;
            }
        }

        .note {
            .teacher-heading {
                font-size: 22px;
            }
        }
    }

    .academic{
        .mainCondent{
            p{
                font-size: 22px;
            }
        }
        .academic-sec {
            border-left: 2px solid #91BF82;
            display: flex;
            flex-direction: column;
            padding: 10px;
    
            span {
                font-size: 22px;
            }
    
            p {
                font-size: 20px;
            }
        }
        .note {
            span {
                font-size: 22px;
            }
        }
    }
    .pathway{
        span {
            font-size: 22px;
        }

        p {
            font-size: 20px;
        }
        .line {
            border-left: 2px solid #91BF82;
            padding-left: 1rem;
        }
    }
    .note {
        span {
            font-size: 22px;
        }
    } 
    .highschool{
        h2{
            font-size: 22px;
        }
        p{
            font-size: 20px;
        }
        .sub-head{
            font-size: 22px;
        }
    }
    .scores{
        p{
            font-size: 20px;
        }
        .sub-head{
            font-size: 22px;
        }
    }
    .activities{

        p{
            font-size: 20px;
        }
        .sub-head{
            font-size: 22px;
        }
    }
    }


.main-s {
    .p-accordion .p-accordion-content {
        padding: 5px 50px !important;
    }
}

.na {
    width: 85%;
    height: 50px;
    margin: auto;
    display: flex;
    align-items: center;
}

@media (max-width: 1190px) {
    .main-s {
        .card {
            flex-direction: column;

            .right,
            .left {
                min-width: 275px;
            }
        }

        .dd {
            .p-accordion-header-text {
                div {
                    gap: 20px;

                    span {
                        line-height: normal;
                    }
                }
            }
        }

    }

}

@media (max-width: 1000px) {
    .dd {
        .personal-section {
            .personal-heading {
                display: flex !important;
                justify-content: flex-start !important;
                align-items: center;
                flex-wrap: wrap !important;
            }

            .personal-field {
                font-size: 18px !important;
                margin: 0px !important;
            }
        }

        .career-sec {
            .career-heading {
                display: flex !important;
                justify-content: flex-start !important;
                align-items: center;
                flex-wrap: wrap !important;
            }

            .career-field {
                font-size: 18px !important;
                margin: 0px !important;
            }
        }

        .academic-section {
            .academic-heading {
                display: flex !important;
                justify-content: flex-start !important;
                align-items: center;
                flex-wrap: wrap !important;
            }

            .academic-field {
                font-size: 18px !important;
                margin: 0px !important;
            }
        }

        .academic-sec {
            border-left: 0px;
            gap: 5px;
            padding: 7px;
        }

        .pathway-section {
            .pathway-heading {
                display: flex !important;
                justify-content: flex-start !important;
                align-items: center;
                flex-wrap: wrap !important;
            }

            .pathway-field {
                font-size: 18px !important;
                margin: 0px !important;
            }
        }
    
        .progress-section {
            .progress-heading {
                display: flex !important;
                justify-content: flex-start !important;
                align-items: center;
                flex-wrap: wrap !important;
            }
            .progress-field {
                font-size: 18px !important;
                margin: 0px !important;
            }
        }
       
        .scores-section {
            .scores-heading {
                display: flex !important;
                justify-content: flex-start !important;
                align-items: center;
                flex-wrap: wrap !important;
            }
            .scores-field {
                font-size: 18px !important;
                margin: 0px !important;
            }
        }
        .activities-section {
            .activities-heading {
                display: flex !important;
                justify-content: flex-start !important;
                align-items: center;
                flex-wrap: wrap !important;
            }
            .activities-field {
                font-size: 18px !important;
                margin: 0px !important;
            }
        }
    }
}

@media (max-width: 600px) {

    .main-s {

        .card {
            padding: 25px;
            margin-bottom: 20px;
        }

        .dd {
            padding: 25px;

            .p-accordion-header-text {
                font-size: 18px;
            }

            .personal-field {
                font-size: 18px !important;
            }

            .career-section {
                .results-section {
                    .gap-6 {
                        gap: 1rem !important;
                    }
                }
            }

            .p-accordion-content {
                padding: 25px 15px !important;

                .flex_Sec {
                    gap: 25px;

                    .border {
                        gap: 10px;

                        div,
                        span {
                            font-size: 16px;
                        }
                    }

                    div,
                    span {
                        font-size: 16px;
                    }
                }
            }

            .section4 div {
                font-size: 16px;
            }

            .p-datatable {
                .p-datatable-thead {
                    height: 45px !important;
                }
            }
        }

    }

}