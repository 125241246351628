.Academic-pathways-container {
    margin: 2rem;
} 

.academic-pathways-form {
    margin: 1rem 1rem 4rem 1rem;
    .pathways-mobile-content {
        display: none !important;
    }
}

.academic-pathways-header {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-bottom: 4px solid #91BF82;
    padding-bottom: 15px;
}

.academic-pathways-subheader {
    color: #000;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    margin-top: 2rem;
}
.academic-pathways-subheaders {
    color: #878D90;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    text-align: left;
}

.pathways-content {
    font-size: 20px;
}

.pathway-radio-buttons {
    margin-top: 20px;
    border-left: 1px solid #D9D9D9;
}

.form-footer {
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
}

.mobile-pathway{
    display: none;
}

@media screen and (max-width:1008px) {
    .academy-section {
        .p-card {
            box-shadow: none !important;
        }
        .pathway-radio-buttons {
            border-left: 0px !important;
        }
        .academic-pathways-form {
            margin:0rem !important;
            padding-top: 14px;
        }
        .academy-label{
            font-weight: bold;
            font-size: 18px;
        }
        .gap-8 {
            gap: 3rem !important;
        }
    }
    .academic-pathways-header {
        font-size: 20px;
    }
}


@media screen and (max-width:780px) {
    .academic-pathways-form {
        .pathways-content {
            display: none !important;
        }
        .pathways-mobile-content {
            display: block !important;
        }
    }
}

.pathway-btn {
    border-radius: 10px;
    border: 3px solid #052941;
    color: #052941;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    cursor: pointer;
    width: 174px;
    min-height: 85px;
    display: flex;
    align-items: center;
}

.pathway-btn-active {
    border-radius: 10px;
    border: 3px solid #052941;
    background: #052941;
    color: #F9F9F9;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    cursor: pointer;
    width: 174px;
    min-height: 85px;
    display: flex;
    align-items: center;
}


@media screen and (max-width:600px) {
    .academy-section{
        .m-4 {
            margin: 0.5rem !important;
        }
        .p-card .p-card-body {
            padding: 0rem !important;
        }
        .p-card .p-card-content {
            padding: 0 !important;
        }
    }
    .pathway-btn-active {
        width: 165px;
        min-height: 85px;
    }
    
    .pathway-btn {
        width: 165px;
        min-height: 85px;
    }
}

@media screen and (max-width:500px) {
    .academy-section {
        .academy-labels{
            font-size: 16px !important;
        }
        .gap-8 {
            gap: 1rem !important;
        }
        .academic-pathways-subheader {
            font-size: 18px;
            margin: 1rem 0;
        }
        .p-button {
            font-size: 16px !important;
            padding: 0.5rem 0.5rem !important;
        }
    }
}

@media screen and (max-width:450px) {
    .academic-pathways-form {
        .pathways-content {
            display: none !important;
        }
        .pathways-mobile-content {
            display: block !important;
        }
    }
    .pathway-btn-active {
        font-size: 14px;
        width: 160px;
        min-height: 85px;
    }
    
    .pathway-btn {
        font-size: 14px;
        width: 160px;
        min-height: 85px;
    }
}

@media screen and (max-width:430px) {
    .pathway-btn-active {
        overflow-wrap: anywhere;
        width: 130px;
        min-height: 85px;
    }
    
    .pathway-btn {
        overflow-wrap: anywhere;
        width: 130px;
        min-height: 85px;
    }
}

@media screen and (max-width:360px) {
    .pathway-btn-active {
        overflow-wrap: anywhere;
        width: 108px;
        min-height: 85px;
    }
    
    .pathway-btn {
        overflow-wrap: anywhere;
        width: 108px;
        min-height: 85px;
    }
}

@media screen and (max-width:767px) {
    .desktop-pathway{
       display: none;
    }
    .mobile-pathway{
        display: block;
    }
    .accordion-content{
        .teacher-notes-section{
            p{
                font-size: 18px;
            }
        }
    }
}